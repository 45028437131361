<template>
  <div class="form">
    <form action="">
      <table>
        <tr>
          <td>
            <input type="text" id="UserName" placeholder="Name" required />
          </td>
        </tr>
        <tr>
          <td>
            <input
              type="number"
              maxlength="6"
              id="UserPhone"
              placeholder="Phone"
              required
            />
          </td>
        </tr>
        <tr>
          <td><input type="email" id="UserEmail" placeholder="Email" /></td>
        </tr>
        <tr>
          <td><input type="text" id="Subject" placeholder="Subject" /></td>
        </tr>
        <tr>
          <td>
            <textarea
              name=""
              id="TextArea"
              cols="30"
              rows="10"
              placeholder="Type Your message here..."
            ></textarea>
          </td>
        </tr>
        <tr>
          <td>
            <div class="submit">
              <button @click="submit">Submit</button>
            </div>
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      newLine: "%0D%0A",
      headerName: "Full Name",
      headerPhone: "Phone Number",
      headerEmail: "Email Address",
      headerMessage: "Message",
    };
  },
  methods: {
    submit() {
      const name = document.getElementById("UserName").value;
      const phone = document.getElementById("UserPhone").value;
      const email = document.getElementById("UserEmail").value;
      const subject = document.getElementById("Subject").value;
      const message = document.getElementById("TextArea").value;

      if (name == "" || phone == "") {
        alert("Please Fill All Required Fields");
      } else {
        const body =
          this.headerName +
          ":" +
          " " +
          name +
          this.newLine +
          this.headerPhone +
          ":" +
          " " +
          phone +
          this.newLine +
          this.headerEmail +
          ":" +
          " " +
          email +
          this.newLine +
          this.headerSubject +
          ":" +
          " " +
          subject +
          this.newLine +
          this.headerMessage +
          ":" +
          " " +
          message +
          this.newLine;

        console.log(body);
        window.open(
          "mailto:hospitalsroyal@gmail.com?subject=Royal Hospital Client&body=" +
            body
        );
      }
    },
  },
};
</script>

<style scoped>
.FormSection {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 5%;
}
.form {
  width: 100%;
}
textarea {
  resize: none;
  height: 100px;
}

input[type="number"] {
  -moz-appearance: textfield;
}
textarea,
input {
  margin-top: 4%;
  width: 100%;
  border: 1px solid #2e3c8b;
  color: #2e3c8b;
}
::placeholder {
  color: #2e3c8b;
}
input {
  height: 40px;
}
table {
  margin: auto;
  width: 80%;
}
td .submit {
  float: right;
}
.submit button {
  width: 100px;
  align-items: left;
  color: #fff;
  background-color: #2e3c8b;
  border: unset;
  padding: 12px 25px 12px 25px;
  margin-top: 20px;
  font-weight: bold;
}
</style>