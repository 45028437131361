<template>
  <div id="contact">
    <div class="cont">
      <div class="contact">
        <div class="blur"><div></div></div>
        <TitlePage
          class="home-title"
          :showButton="false"
          :header="headerTitle"
          :description="description"
        />
      </div>
      <div class="FormSection">
        <div class="ContactDetailes">
          <h2>Contact Us</h2>
          <p>
            Kona ya Bwiru, Mwanza <br />
            hospitalsroyal@gmail.com <br />
            +255746044400
          </p>
          <div class="SocialIcons">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/Royal-Hospitals-107235220787568/"
                  target="_blank"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 155.139 155.139"
                    style="enable-background: new 0 0 155.139 155.139"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        id="f_1_"
                        style="fill: #010002"
                        d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
		c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
		v20.341H37.29v27.585h23.814v70.761H89.584z"
                      />
                    </g>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/royal_hospitals/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.518"
                    height="31.511"
                    viewBox="0 0 31.518 31.511"
                  >
                    <path
                      id="Icon_awesome-instagram"
                      data-name="Icon awesome-instagram"
                      d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
                      transform="translate(0.005 -2.237)"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Form />
      </div>
    </div>

    <StaticMap />
    <GoogleMap />
    <Footer class="footer" />
  </div>
</template>

<script>

// import StaticMap from 'vue-static-map';
import Footer from "../components/Footer";
import GoogleMap from "../components/GoogleMap";
import Form from "../components/Form";
import TitlePage from "../components/layouts/TitlePage";
export default {
  name: "Contact",
  components: {
    TitlePage,
    Footer,
    GoogleMap,
    Form,
    // StaticMap
  },
  data() {
    return {
      headerTitle: "Contact Us",
      description:
        "We want to hear from you, share with us your feedback, your suggestions, and request an appointment.",
    };
  },
};
</script>

<style scoped>
.cont {
  background-color: #eeeeee;
  padding-bottom: 50px;
}
.FormSection {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 5%;
}
.ContactDetailes {
  width: 50%;
}
.ContactDetailes p {
  line-height: 40px;
}
.ContactDetailes h2 {
  color: #ce515c;
}
.blur {
  position: absolute;
  background-image: url("../assets/images/outside-shoot-35.jpg");
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  -webkit-filter: blur(5px);
  width: 100%;
  height: 100%;
}
.contact {
  contain: content;
}
.home-title {
  position: relative;
  height: 70vh;
  display: grid;
  place-items: center;
}
.SocialIcons ul {
  margin: auto;
  display: flex;
  padding: unset;
  width: 100px;
}
.SocialIcons ul li {
  margin: auto;
  list-style: none;
  padding-right: 2%;
  width: 25px;
  height: 25px;
}
.SocialIcons ul li svg {
  width: 23px;
  height: 23px;
  fill: #000;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
.SocialIcons ul li svg:hover {
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 720px) {
  .FormSection {
    display: block;
  }
  .ContactDetailes {
    width: 100%;
  }
}
</style>