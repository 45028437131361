<template>
  <div class="googleMap">
    <!-- <button @click="drawMarcker">Draw Marker</button> -->
    <!-- <GmapMap
    class="googleMap"
  :center="{ lat: -2.491314677133466, lng: 32.902503079427085 }"
  :zoom="14"
>
  <GmapMarker
  class="marker"
    :key="index"
    v-for="(m, index) in markers"
    :position="m.position"
    :clickable="true"
    :draggable="false"
    @click="center=m.position"
  />
</GmapMap> -->
  </div>
</template>

<script>

export default {
  name: "GoogleMap",
  // data(){
  //   return{
  //     markers: this.drawMarcker()
  //   }
  // },
  // methods:{
  //   drawMarcker(){
  //     const home= { lat: -2.491314677133466, lng: 32.902503079427085 };
  //     var marking =this.markers = [
  //       {
  //       position: home
  //     }
  //     ]
  //     return marking
      
  //   },
  // }
};
</script>

<style scoped>
.googleMap {
  /* margin-top: 100px; */
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/RoyalHospitaLocation2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>